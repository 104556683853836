.progress-table {
  thead th {
    position: sticky;
    top: 0;
    z-index: 1;

    &:first-child {
      background-position: var(--bs-white);
      left: 0;
      z-index: 3;
    }
  }

  tbody td:first-child {
    position: sticky;
    left: 0;
    z-index: 2;
  }
}