.modal-body > .flush {
  --reset-margin: calc(-1 * var(--bs-modal-padding));
  margin-left: var(--reset-margin);
  margin-right: var(--reset-margin);
  padding-left: var(--bs-modal-padding);
  padding-right: var(--bs-modal-padding);

  &.top {
    margin-top: var(--reset-margin);
  }
}
