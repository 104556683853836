.notification {
  background-color: transparent;
  transition: background-color 250ms ease-in;
}

.notification-enter {
  opacity: 0.01;
}

.notification-enter.notification-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}

.notification-exit {
  opacity: 1;
}

.notification-exit.notification-exit-active {
  opacity: 0.01;
  transition: opacity 250ms ease-in;
}
