// Overrides for Bootstrap variables go here.
// Complete list of variables: https://github.com/twbs/bootstrap/blob/main/scss/_variables.scss

// COLOR TOKENS
// -------

$white: #FFFFFF;
$black: #2B2A2F;

// Primary
$sn-clr-black: $black;
$sn-clr-green: #005661;
$sn-clr-land: #1E97A4;
$sn-clr-sea: #8DCDD4;
$sn-clr-medium-green: #CCDDDF;
$sn-clr-light-green: #F0F5F6;
$sn-clr-breeze: #FDFDFD;

// UI
$sn-clr-dark-grey: #54515B;
$sn-clr-breadcrumb-grey: #6E767D;
$sn-clr-deep-grey: #A1A8AF;
$sn-clr-border-grey: #CED4DA;
$sn-clr-ui-grey: #DDE4EB;
$sn-clr-bread-grey: #E9ECEF;
$sn-clr-medium-grey: #F1EFEF;
$sn-clr-light-grey: #FAF8F8;
$sn-clr-white: $white;

// Secondary
$sn-clr-dark-red: #893139;
$sn-clr-red: #C24447;
$sn-clr-energy: #ED694F;
$sn-clr-warmth: #D98D75;
$sn-clr-fire: #F29559;
$sn-clr-sand: #F4EEEA;
$sn-clr-light: #F6F2EA;

// Accent
$sn-clr-dark-blue: #425892;
$sn-clr-sky: #EEF6FF;
$sn-clr-purple: #74559A;
$sn-clr-light-purple: #FBF8FF;
$sn-clr-storm: #425892;
$sn-clr-yellow: #FFD600;


// Bootstrap overrides
// -------

// Colors
$primary: $sn-clr-green;
$success: $sn-clr-green;
$secondary: $sn-clr-dark-grey;
$info: lighten($sn-clr-land, 3%); // Achieve 4.5:1 contrast ratio
$warning: $sn-clr-fire;
$danger: $sn-clr-red;
$light: $sn-clr-light-grey;
$dark: $sn-clr-black;

// Add custom colors to map
// These will be available as custom properties:
//   var(--bs-sn-clr-green), var(--bs-sn-clr-medium-green), etc.
$colors: (
  "white": $white,
  "black": $black,
  "sn-clr-green": $sn-clr-green,
  "sn-clr-medium-green": $sn-clr-medium-green,
  "sn-clr-light-green": $sn-clr-light-green,
  "sn-clr-dark-grey": $sn-clr-dark-grey,
  "sn-clr-bread-grey": $sn-clr-bread-grey,
  "sn-clr-breadcrumb-grey": $sn-clr-breadcrumb-grey,
  "sn-clr-deep-grey": $sn-clr-deep-grey,
  "sn-clr-light-grey": $sn-clr-light-grey,
  "sn-clr-border-grey": $sn-clr-border-grey,
  "sn-clr-land": $sn-clr-land,
  "sn-clr-fire": $sn-clr-fire,
  "sn-clr-purple": $sn-clr-purple,
  "sn-clr-red": $sn-clr-red,
  "sn-clr-dark-blue": $sn-clr-dark-blue,
  "sn-clr-storm": $sn-clr-storm,
  "sn-clr-breeze": $sn-clr-breeze
);

$body-color: $sn-clr-black;
$border-color: $sn-clr-medium-grey;
$hover-highlight: $sn-clr-light-green;

$component-active-color: $primary;
$component-active-bg: rgba($primary, 0.1);

// Fonts
$font-size-base: 1rem;
$font-family-sans-serif: 'Open Sans', sans-serif;
$font-size-sm: $font-size-base * .875;

$h1-font-size: $font-size-base * 1.5; // 24px
$h2-font-size: $font-size-base * 1.25; // 20px
$h3-font-size: $font-size-base * 1.15; // 18px
$h4-font-size: $font-size-base; // 16px
$h5-font-size: $font-size-base * 0.875; // 14px
$h6-font-size: $font-size-base * 0.875; // 14px 

$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 800;

$letter-spacing: 0.0375rem;

// Tables
$table-th-color: $sn-clr-dark-grey;
$table-th-size: $font-size-sm;

$table-border-color: $sn-clr-medium-grey;
$table-border: 1px solid $table-border-color;

$table-hover-bg: $sn-clr-light-green;
$table-link-color: $sn-clr-black;
$table-link-font-weight: $font-weight-semibold;

// Inputs
$input-border-color: $sn-clr-border-grey;
$label-color: $sn-clr-dark-grey;
$label-font-weight: $font-weight-medium;

// Borders
$border-green: 1px solid $sn-clr-green;
$border-grey: 1px solid $sn-clr-ui-grey;
$header-border: 2px solid $sn-clr-ui-grey;
$nav-active-border: 2px solid $sn-clr-green;

// Border radius
$border-radius: 0.275rem;

// Spacer
$spacer: 1rem; //16px
$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
);

// Underline Nav
$nav-item-color: $sn-clr-dark-grey;
$nav-item-active-color: $sn-clr-black;

// Box-shadow 
$sn-shadow-md: 0px 2px 4px rgba(0, 0, 0, 0.075);
$sn-shadow-sm: 0rem 0.0625rem 0.0625rem rgba(31,33,36,.1);

// Button 
$btn-secondary-background: $sn-clr-white;
$btn-secondary-hover-background: $sn-clr-medium-grey;
$btn-secondary-color: $sn-clr-black;
$btn-secondary-border-color: $sn-clr-deep-grey;

// Badge
$badge-font-weight: $font-weight-bold;
$badge-text-transform: uppercase;

// Z-Index
$zindex-sticky: 1000;
$zindex-fixed: 1010;
$zindex-dropdown: 1020;

// Forms
$form-switch-focus-color: $sn-clr-ui-grey;

// List group
$list-group-disabled-color: lighten($sn-clr-black, 30%);

// Modals
$modal-header-padding-y: $spacer * .5;

// Popovers
$popover-max-width: 100%;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px,
  xxxl: 1600px
);