.server {
  font-weight: 600;
  margin: 0;
  padding: 0.5em;
  text-align: center;

  &.development {
    background-color: silver;
    color: #303030;
  }
  &.qa {
    background-color: orange;
    color: #3d2800;
  }
  &.staging {
    background-color: yellow;
    color: #575700;
  }
}