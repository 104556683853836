:root {
  --clear-btn-color: hsl(0, 0%, 80%);
}

.switch-label {
  width: calc(100% - 2.5em); // 2.5em is the width of the switch
}

.check-input {
  padding: 6px 12px;
  display: inline-block;
  border: 1px solid var(--bs-light);
}

.check-input:last-child {
  margin: 0;
}

.check-input:focus-within { 
  border: 1px solid var(--bs-primary);
}

.check-input.selected {
  background-color: var(--bs-sn-clr-light-green);
  border: 1px solid var(--bs-primary);
  color: var(--bs-primary);
}

// Datepicker
// * Add clear button to datepicker
// * Match React Select clear button styles
.sn-datepicker {
  --box-shadow-color: 0, 86, 97;
  --clear-btn-color: hsl(0, 0%, 80%);
  --clear-btn-hover-color: hsl(0, 0%, 60%);
  --input-width: 100%;
  
  align-items: center;
  display: flex;
  padding-block: 0;
  
  &__clear-btn.btn { // added specificity to override the global .btn styles
    color: var(--clear-btn-color);
    line-height: 1;
    padding: 0;

    &:hover {
      color: var(--clear-btn-hover-color);
    }

    & > svg {
      display: inline-block;
      fill: currentColor;
      line-height: 1;
      stroke: currentColor;
      stroke-width: 0;
    }
  }

  &.is-clearable {
    --input-width: calc(100% - 22px);
  }

  &.is-invalid {
    --box-shadow-color: var(--bs-danger-rgb);
  }

  &.is-disabled { // mirror Bootstrap .form-control disabled styles
    background-color: var(--bs-secondary-bg);
    opacity: 1;
  }

  &:focus-within {
    --clear-btn-color: hsl(0, 0%, 40%);
    --clear-btn-hover-color: hsl(0, 0%, 20%);

    box-shadow: 0 0 0 0.25rem rgba(var(--box-shadow-color), 0.25);
  }

  & > .flatpickr-input {
    background-color: transparent;
    border: none;
    outline: none;
    padding: 6px 0;
    width: var(--input-width);
  }
}

/*
 * DatePicker (Flatpickr) calendar
 * `.dayContainer` is the container for all days in the calendar, required for specificity
*/
.dayContainer > .flatpickr-day {
  &.today {
    border-color: var(--bs-primary);

    &:hover,
    &:focus {
      background: var(--bs-sn-clr-light-green);
      border-color: var(--bs-primary);
      color: var(--bs-primary);
    }
  }

  &:hover,
  &:focus {
    background: var(--bs-sn-clr-light-green);
    border-color: var(--bs-sn-clr-light-green);
    color: var(--bs-primary);
  }

  &.today.inRange,
  &.prevMonthDay.inRange,
  &.nextMonthDay.inRange,
  &.prevMonthDay.today.inRange,
  &.nextMonthDay.today.inRange,
  &.inRange {
    background: var(--bs-sn-clr-light-green);
    border-color: var(--bs-sn-clr-light-green);
    color: var(--bs-primary);
    -webkit-box-shadow: -5px 0 0 var(--bs-sn-clr-light-green), 5px 0 0 var(--bs-sn-clr-light-green);
    box-shadow: -5px 0 0 var(--bs-sn-clr-light-green), 5px 0 0 var(--bs-sn-clr-light-green);
  }

  &.selected,
  &.selected:hover,
  &.selected:focus,
  &.startRange,
  &.startRange:hover,
  &.startRange:focus,
  &.endRange,
  &.endRange:hover,
  &.endRange:focus,
  &.selected.inRange,
  &.startRange.inRange,
  &.endRange.inRange,
  &.selected.prevMonthDay,
  &.startRange.prevMonthDay,
  &.endRange.prevMonthDay,
  &.selected.nextMonthDay,
  &.startRange.nextMonthDay,
  &.endRange.nextMonthDay {
    background: var(--bs-primary);
    border-color: var(--bs-primary);
    color: var(--bs-white);
  }

  &.selected.startRange + .endRange:not(:nth-child(7n+1)),
  &.startRange.startRange + .endRange:not(:nth-child(7n+1)),
  &.endRange.startRange + .endRange:not(:nth-child(7n+1)) {
    -webkit-box-shadow: -10px 0 0 var(--bs-primary);
    box-shadow: -10px 0 0 var(--bs-primary);
  }
}
