.clear-indicator {
  -webkit-appearance: none;
  appearance: none;
  all: unset;
  color: var(--bs-sn-clr-deep-grey);
  font-size: 110%;
  padding: 1px;
  visibility: visible;

  &:focus,
  &:hover {
    color: var(--bs-sn-clr-dark-grey);
  }
  &:focus-visible {
    box-shadow: 0 0 0 0.1rem rgba(var(--bs-primary-rgb), 0.25);
  }
}