.list-group-item {
  --bs-list-group-active-bg: var(--bs-sn-clr-light-green);
}

// NOTE: We might want to do this with warning, info, etc. as well.
.list-group-item-danger {
  --bs-list-group-bg: #{tint-color($danger-bg-subtle, 40%)};
  --bs-list-group-border-color: #{tint-color($danger-border-subtle, 40%)};
  --bs-list-group-action-hover-bg: #{tint-color($danger-bg-subtle, 20%)};
  --bs-list-group-action-active-bg: #{tint-color($danger-bg-subtle, 20%)};
  --bs-list-group-active-color: var(--bs-danger-text-emphasis);
  --bs-list-group-active-bg: var(--bs-danger-bg-subtle);
  --bs-list-group-active-border-color: var(--bs-danger-border-subtle);
}
