.auto-complete > .list-group-item {
  cursor: default;

  & > .icon,
  & small {
    opacity: 0.75;
  }
}

.auto-complete > [aria-selected=true],
.auto-complete > .list-group-item:hover {
  background-color: var(--bs-primary);
  color: var(--bs-white);
}
