button.journal-row-buttons {
  margin-left: -0.25rem;
  padding: 0 0.25rem;

  &:hover,
  &:focus-visible {
    color: var(--bs-primary);
  }

  &:focus-visible {
    box-shadow: 0 0 0 0.25rem rgba(var(--bs-primary-rgb), 0.25);
  }
}