// Shortcodes container animations
.shortcodes {
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 300ms ease-in-out;
}
.shortcodes > div {
  overflow: hidden;
}

.shortcodes.open {
  grid-template-rows: 1fr;
}

.shortcodes-icon {
  margin-left: auto;
  transition: transform 300ms;
}

.shortcodes-icon.open {
  transform: rotate(180deg);
}