// Header Component
.header {
  padding: $spacer * 1.5;
  border-bottom: $header-border;
  background: $sn-clr-white;
}

.header .breadcrumb {
  margin-bottom: 0;
}

.breadcrumb a {
  text-decoration: none;
  font-weight: $font-weight-medium;
}

.flex-col-gap-sm {
  column-gap: $spacer;
}
