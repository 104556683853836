// Buttons
.btn-default {
  box-shadow: $sn-shadow-sm;
}

.btn-secondary {
  background-color: $btn-secondary-background;
  color: $btn-secondary-color;
  border-color: $btn-secondary-border-color;
}

.btn-secondary:hover, .btn-secondary:active, .btn.show {
  background-color: $btn-secondary-hover-background !important;
  color: $btn-secondary-color !important;
}

.btn-plain {
  --bs-btn-color: var(--bs-primary);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-btn-color);
  --bs-btn-hover-bg: var(--bs-btn-bg);
  --bs-btn-hover-border-color: var(--bs-btn-border-color);
  --bs-btn-active-color: var(--bs-btn-hover-color);
  --bs-btn-active-bg: var(--bs-btn-hover-bg);
  --bs-btn-active-border-color: var(--bs-btn-hover-border-color);
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-disabled-bg: transparent;
  
  box-shadow: none;
  text-decoration: none;
  background-color: transparent;

  &:hover,
  &:focus,
  &:focus-visible,
  &:active {
    color: inherit;
    text-decoration: underline;
  }

  &:focus-visible {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(var(--bs-primary-rgb), 0.25);
  }
}

.btn-plain.btn-danger, .btn-plain.btn-danger:active {
  color: $danger;
}
.btn-plain.btn-danger-alt, .btn-plain.btn-danger-alt:active {
  background-color: var(--bs-danger-bg-subtle);
  border-radius: 0.125em;
  color: rgb(127, 41, 41); /// AAA contrast ratio (7:1) against background-color

  &:focus-visible {
    outline: 0;
    box-shadow: 0 0 0 0.125rem rgba(127, 41, 41, 0.25);
  }
}

.btn-plain.btn-primary, .btn-plain.btn-primary:active {
  color: $primary;
}

.icon-btn.dropdown-toggle, .icon-btn.dropdown-toggle:active {
  border: none;
}

.icon-btn.dropdown-toggle:hover {
  background-color: $sn-clr-medium-grey !important;
}

.icon-btn.dropdown-toggle::after {
  display: none;
}

.btn-clean {
  width: 100px;
  margin: 0 5px;
  display: inline-block;
  padding: 10px 5px;
  border: 1px solid #ccc;
  position: relative;
  text-decoration: none;
  border-radius: 3px;

  text-align: center;

  .icon,.btn-label {
    display: block;
    text-align: center;
   }
  svg {
    display: block;
    margin-left: auto;
    margin-right: auto;
   }
   .btn-label {
      font-size: 12px;
      padding: 10px 5px 0 5px;
    }
  .icon-sm { font-size: 16px; }
  .icon-md { font-size: 28px; }
  .icon-lg { font-size: 38px; }
  .bubble { display: block; position: absolute; top: 1px; left: 4px;}
  &:hover {
    color: #333;
    background-color: #e6e6e6;
    border-color: #adadad;
    cursor: pointer;
  }

  &.btn-clean-wide {
    width: 120px;
  }

  &.reports {
    color: black;
  }
}

.btn-centered {
  display: block;
  text-align: center;
}

.btn-text {
  background-color: transparent;
  border: none;
  color: $primary;
  display: inline-block;
  font-weight: $font-weight-semibold;
  padding: 0;
  text-decoration: none;
  cursor: pointer;

  &:focus-visible {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(var(--bs-primary-rgb), 0.25);
    padding: $spacer * 0.125 $spacer * 0.25;
    margin: $spacer * -0.125 $spacer * -0.25;
  }
}

.btn-alt-primary {
  --bs-btn-color: var(--bs-primary);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: var(--bs-primary);
  --bs-btn-hover-color: var(--bs-btn-color);
  --bs-btn-hover-bg: var(--bs-sn-clr-light-green);
  --bs-btn-hover-border-color: var(--bs-btn-border-color);
  --bs-btn-active-color: var(--bs-btn-hover-color);
  --bs-btn-active-bg: var(--bs-btn-hover-bg);
  --bs-btn-active-border-color: var(--bs-btn-hover-border-color);

  &:focus-visible {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(var(--bs-primary-rgb), 0.25);
  }
}

.btn-alt-secondary {
  --bs-btn-color: var(--bs-body-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-btn-color);
  --bs-btn-hover-bg: var(--bs-sn-clr-light-green);
  --bs-btn-hover-border-color: var(--bs-sn-clr-light-green);
  --bs-btn-active-color: var(--bs-btn-hover-color);
  --bs-btn-active-bg: var(--bs-btn-hover-bg);
  --bs-btn-active-border-color: var(--bs-btn-hover-border-color);
  --bs-btn-disabled-border-color: transparent;

  &:focus-visible {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(var(--bs-primary-rgb), 0.25);
  }
}

.btn-alt-outline {
  --bs-btn-color: var(--bs-secondary);
  --bs-btn-bg: var(--bs-white);
  --bs-btn-border-color: var(--bs-secondary);
  --bs-btn-hover-color: var(--bs-body-color);
  --bs-btn-hover-bg: var(--bs-sn-clr-bread-grey);
  --bs-btn-hover-border-color: var(--bs-btn-border-color);
  --bs-btn-active-color: var(--bs-btn-hover-color);
  --bs-btn-active-bg: var(--bs-btn-hover-bg);
  --bs-btn-active-border-color: var(--bs-btn-hover-border-color);

  &:focus-visible {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(var(--bs-primary-rgb), 0.25);
  }
}
