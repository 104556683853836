.highlighted {
  line-height: 1.2em;
  background: var(--bs-light);
  border: 1px solid var(--bs-border-color);
  padding: 2em;
  max-height: 45em;
  overflow-y: auto;
  margin-bottom: 1em;
  overflow-wrap: break-word;
}

.highlighted mark {
  background: var(--bs-sn-clr-medium-green);
}

.updated-text {
  margin-left: 10px;
  color: var(--bs-sn-clr-green);
  font-weight: 100;
  font-size: 0.8em;
}

.task-link{
  color: var(--bs-black);
  font-weight: 600;
  font-size: 1.15rem;
  text-decoration: none;
}
.task-link:hover{
  text-decoration: underline;
}
