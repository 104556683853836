.card {
  border-color: transparent;

  &:hover {
    border-color: $sn-clr-green;
  }

  &#{&}-facilitator {
    background-color: $sn-clr-light-purple;
    border-color: $sn-clr-purple;

    .btn-alt-secondary {
      --bs-btn-hover-bg: var(--bs-sn-clr-breeze);
    }
  }

  &#{&}-post {
    background-color: $sn-clr-breeze;
    border-color: $sn-clr-breeze;
  }

  &#{&}-reply {
    background-color: $sn-clr-light-green;
    border-color: $sn-clr-light-green;
  }
}

.comment-card:nth-child(odd) > .card-post {
  background-color: $sn-clr-light-green;
  border-color: $sn-clr-light-green;

  .btn-alt-secondary {
    --bs-btn-hover-bg: var(--bs-sn-clr-breeze);
  }
}