nav > ol.breadcrumb {
  margin: 0;
}

.nav.nav-tabs .nav-link {
  color: var(--bs-secondary, $sn-clr-dark-grey);
  font-weight: 600;
}

.nav.nav-tabs .nav-link.active {
  color: $sn-clr-green;
}

// Underline nav (Header nav)
nav:has(.underline-nav) {
  margin-bottom: -2px;
}

.underline-nav .nav-link {
  font-weight: $font-weight-medium;
  color: $nav-item-color;
}

.underline-nav .nav-link.active {
  border-bottom: $nav-active-border;
  color: $nav-item-active-color;
}

.underline-nav .nav-link:hover {
  border-bottom: $nav-active-border;
  color: $nav-item-active-color;
}

// Underline nav (Thin) ("internal" nav)
.nav.nav-uppercase .nav-link {
  font-size: smaller;
  text-transform: uppercase;
}

.nav-tabs.nav-underline-thin {
  --bs-nav-tabs-border-width: 0;
  --bs-nav-tabs-border-color: transparent;

  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
}

.nav-underline-thin > .nav-item {
  margin-bottom: -1px;
}

.nav-underline-thin .nav-link {
  font-weight: $font-weight-medium;
  color: $nav-item-color;
}

.nav-underline-thin .nav-link.active {
  border-bottom: $nav-active-border;
  border-bottom-width: 1px;
  color: $nav-item-active-color;
}

.nav-underline-thin .nav-link:hover {
  border-bottom: $nav-active-border;
  border-bottom-width: 1px;
  color: $nav-item-active-color;
}