.header-grid {
  align-items: center;
  background-color: var(--bs-white);
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 1.5rem;
}

.title {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  margin: 0;
}

ol.steps {
  counter-reset: li; /* Initiate a counter */
  align-items: center;
  background-color: rgba(255, 255, 255, 0.2);
  display: flex;
  grid-column: 1 / 3;
  grid-row: 2 / 3;
  list-style: none;
  margin: 0 auto;
  padding-left: 0;

  @media (min-width: 992px) {
    & {
      grid-row: 1 / 3;
    }
  }
}

ol.steps > li {
  counter-increment: li; /* Increment the counter by 1 */
  align-items: center;
  color: var(--bs-sn-clr-deep-grey);
  display: flex;
  font-weight: 600;
  margin-right: 0.5rem;
}

ol.steps > li.active,
ol.steps > li.completed {
  color: var(--bs-black);
}

ol.steps > li:last-child {
  margin-right: 0;
}

ol.steps > li::before {
  content: counter(li); /* Use the counter as content */
  align-items: center;
  border: 1.5px solid;
  border-radius: 50%;
  color: inherit;
  display: inline-flex;
  font-size: 0.7em;
  height: 1.7em;
  justify-content: center;
  margin-right: 0.5rem;
  width: 1.7em;
}

ol.steps > li.active::before {
  color: var(--bs-primary);
}

ol.steps > li.completed::before {
  content: "";
  background-color: #5ADC8E;
  background-image: url('data:image/svg+xml,<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M20.781 5.46906C20.8508 5.53873 20.9062 5.62149 20.944 5.71261C20.9818 5.80373 21.0013 5.90141 21.0013 6.00006C21.0013 6.09871 20.9818 6.19639 20.944 6.28751C20.9062 6.37863 20.8508 6.46139 20.781 6.53106L10.281 17.0311C10.2113 17.1009 10.1285 17.1563 10.0374 17.1941C9.9463 17.2319 9.84862 17.2514 9.74997 17.2514C9.65132 17.2514 9.55364 17.2319 9.46252 17.1941C9.3714 17.1563 9.28864 17.1009 9.21897 17.0311L3.96897 11.7811C3.82814 11.6402 3.74902 11.4492 3.74902 11.2501C3.74902 11.0509 3.82814 10.8599 3.96897 10.7191C4.1098 10.5782 4.30081 10.4991 4.49997 10.4991C4.69913 10.4991 4.89014 10.5782 5.03097 10.7191L9.74997 15.4396L19.719 5.46906C19.7886 5.39921 19.8714 5.3438 19.9625 5.30599C20.0536 5.26818 20.1513 5.24872 20.25 5.24872C20.3486 5.24872 20.4463 5.26818 20.5374 5.30599C20.6285 5.3438 20.7113 5.39921 20.781 5.46906Z" fill="white"/></svg>');
  border-color: #5ADC8E;
}

ol.steps > li:not(:last-child)::after {
  align-items: center;
  border-top: 1.5px solid;
  color: var(--bs-sn-clr-deep-grey);
  content: "";
  display: inline-flex;
  height: 0.5px;
  margin-left: 0.5rem;
  width: 2rem;
}

.close {
  justify-self: end;
  grid-column: 2 / 3;
  grid-row: 1 / 2;
}
