.resource {
  background-color: transparent;
  transition: background-color 300ms ease-in;
}

.resource-enter {
  opacity: 0.01;
}

.resource-enter.resource-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.resource-enter,
.resource-enter-done {
  background-color: rgba(#F29559, 0.3); /* fire */
}

.resource-exit {
  opacity: 1;
}

.resource-exit.resource-exit-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}