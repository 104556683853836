.skeleton {
  background-color: rgb(199, 199, 199);
  border-radius: 0.25rem;

  &.title { 
    height: 1.25rem;
    margin-bottom: 1rem;
  }
  
  &.text {
    display: inline-block;
    height: 0.75rem;
    width: 8rem;
  }

  &.strong {
    min-height: 1rem;
  }
  
  &.width-100 {
    width: 100px;
  }
  
  &.width-full {
    width: 100%;
  }
  
  &.width-75p {
    width: 75%;
  }
  
  &.width-66p {
    width: 66%;
  }
  
  &.width-half {
    width: 50%;
  }
  
  &.width-quarter {
    width: 25%;
  }
  
  &.profile-circle {
    width: 150px;
    height: 150px;
    border-radius: 50%;
  }
  
  &.button {
    display: inline-block;
    height: 1.5rem;
    width: 100px;
  }
  
  &.input {
    background-color: #efefef;
    height: 2rem;
  }

  &.icon {
    display: inline-block;
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 50%;

    &.small {
      height: 1rem;
      width: 1rem;
    }
    &.large {
      height: 2rem;
      width: 2rem;
    }
  }
}

/* animation */
@keyframes pulse {
  50% {
      opacity: .5;
  }
}

.animate-pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}
