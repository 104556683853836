.badge {
  font-weight: $badge-font-weight;
  text-transform: $badge-text-transform;
  margin: 0;

  &.badge-green {
    background-color: $sn-clr-light-green;
    color: $sn-clr-green;

    &.badge-alt {
      background-color: darken($sn-clr-light-green, 10%);
      color: darken($sn-clr-green, 8%);
    }
  }

  &.badge-green.badge-reverse {
    background-color: $sn-clr-green;
    color: $sn-clr-light-green;

    &.badge-alt {
      background-color: lighten($sn-clr-green, 12%);
      color: lighten($sn-clr-light-green, 10%);
    }
  }

  &.badge-blue {
    background-color: $sn-clr-sky;
    color: $sn-clr-dark-blue;

    &.badge-alt {
      background-color: darken($sn-clr-sky, 8%);
      color: darken($sn-clr-dark-blue, 8%);
    }
  }

  &.badge-blue.badge-reverse {
    background-color: $sn-clr-dark-blue;
    color: $sn-clr-sky;

    &.badge-alt {
      background-color: lighten($sn-clr-dark-blue, 14%);
      color: lighten($sn-clr-sky, 10%);
    }
  }

  &.badge-red {
    background-color: $sn-clr-sand;
    color: $sn-clr-dark-red;

    &.badge-alt {
      background-color: darken($sn-clr-sand, 10%);
      color: darken($sn-clr-dark-red, 8%);
    }
  }

  &.badge-red.badge-reverse {
    background-color: $sn-clr-dark-red;
    color: $sn-clr-sand;

    &.badge-alt {
      background-color: lighten($sn-clr-dark-red, 12%);
      color: lighten($sn-clr-sand, 10%);
    }
  }

  &.badge-orange {
    background-color: $sn-clr-sand;
    color: $sn-clr-fire;

    &.badge-alt {
      background-color: darken($sn-clr-sand, 8%);
      color: darken($sn-clr-fire, 10%);
    }
  }

  &.badge-orange.badge-reverse {
    background-color: $sn-clr-fire;
    color: $sn-clr-sand;

    &.badge-alt {
      background-color: lighten($sn-clr-fire, 8%);
      color: lighten($sn-clr-sand, 12%);
    }
  }

  &.badge-grey {
    background-color: $sn-clr-deep-grey;
    color: $white;

    &.badge-alt {
      background-color: darken($sn-clr-deep-grey, 10%);
    }
  }

  &.badge-grey.badge-reverse {
    background-color: $sn-clr-bread-grey;
    color: $black;

    &.badge-alt {
      background-color: darken($sn-clr-bread-grey, 8%);
    }
  }

  &.badge-sm {
    font-size: 0.625em;
  }

  &.badge-xs {
    font-size: 0.5em;
  }

  // Discussion Boards and Journals
  &.badge-db1,
  &.badge-j1 {
    background-color: #CAE9EC;
    color: $sn-clr-land;
  }
  &.badge-db2,
  &.badge-j2 {
    background-color: #FEDDD7;
    color: $sn-clr-energy;
  }
  &.badge-db3,
  &.badge-j3 {
    background-color: #E7D7FC;
    color: $sn-clr-purple;
  }
  &.badge-db4,
  &.badge-j4 {
    background-color: $sn-clr-sky;
    color: $sn-clr-storm;
  }
  &.badge-db5,
  &.badge-j5 {
    background-color: $sn-clr-light-green;
    color: $sn-clr-green;
  }
  &.badge-db6,
  &.badge-j6 {
    background-color: $sn-clr-sand;
    color: $sn-clr-dark-red;
  }
}
