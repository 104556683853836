.search-wrapper {
  position: relative;
}

.global-input-search {
  border-radius: 22px; /* half the height */
  width: 20rem;

  /* Adaptable background, border and placeholder colour */
  background-color: rgba(255,255,255,0.2);
  border-color: rgba(0, 0, 0, 0.05);
  
  &::placeholder {
    color: rgba(255,255,255,0.5)
  }
}

#search-spinner {
  color: $sn-clr-medium-green;
  position: absolute;
  right: 10px;
  top: 14px;
}

#search-results {
  background-color: var(--bs-white);
  border: 1px var(--bs-gray-400) solid;
  border-radius: 5px;
  -moz-box-shadow: 1px 2px 6px #ccc;
  -webkit-box-shadow: 1px 2px 6px #ccc;
  box-shadow: 1px 2px 6px #ccc;
  overflow: hidden;
  position: absolute;
  width: 20rem;
  z-index: 1020; /* Over `sticky-top`, equivalent to dropdown */
}

#search-results .registered {
  color: $sn-clr-dark-blue;
}

#search-results .cancelled,
#search-results .removed {
  color: $sn-clr-dark-grey;
  text-decoration: line-through;

  a {
    color: inherit;
  }
}

#search-results p {
  margin: 0;
  padding: 0;
}

#search-results p.main {
  align-items: center;
  display: flex;
  font-size: 1rem;
  font-weight: 600;

  a {
    color: inherit;
  }
}

#search-results a {
  display: block;
  text-decoration: none;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

#search-results ul {
  color: var(--bs-black);
  max-height: 75vh;
  overflow-y: auto;
  padding: 0;
  margin: 0;
}

#search-results li {
  font-size: 0.875rem;
  list-style: none;
  border-bottom: 1px var(--bs-gray-400) solid;
  padding: 5px;
  text-overflow: ellipsis;
  overflow: hidden;

  &:last-child {
    border-bottom: none;
  }
}

#search-results mark {
  padding: 0;
}
