.icon-background:hover{
  background-color: $light !important;
  border-color: transparent;
  border-radius: var(--bs-border-radius);
}

.question {
  background-color: transparent;
  transition: background-color 300ms ease-in;
}

.question-enter {
  opacity: 0.01;
}

.question-enter.question-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.question-enter,
.question-enter-done {
  background-color: var(--bs-sn-clr-light-green); /* fire */
}

.question-exit {
  opacity: 1;
}

.question-exit.question-exit-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}
