.clearable-input {
  &[disabled] {
    background-color: var(--bs-secondary-bg);
    opacity: 1;
  }

  &:focus-within {
    box-shadow: 0 0 0 0.25rem rgba(var(--bs-primary-rgb), 0.25);
  }

  & > input {
    border: none;
    outline: none;

    &:placeholder-shown {
      & ~ button {
        visibility: hidden;
      }
    }
  }
}
