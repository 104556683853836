.group-header {
  align-items: center;
  column-gap: 1rem;
  display: grid;
  grid-template-columns: min-content 1fr min-content;
  grid-template-areas: "icon title actions" ". status .";
  width: 100%;

  & > .status {
    grid-area: status;
    padding-top: 0.25rem;
  }
}

