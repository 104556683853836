.list-item--active {
  background-color: var(--bs-sn-clr-light-green);
}

.messages {
  &--current-user {
    --bs-border-color: var(--bs-sn-clr-sea);
    background-color: var(--bs-sn-clr-light-green);
  }
}
