.heading {
  width: 12em;
}

.bi-table-container {
  overflow: auto;
  transition: height 0.2s ease-in;
}

.bi-summary-table {
  border: none;
  min-width: max-content;

  & th,
  & td {
    border-bottom: none;
    padding: 2px 4px;
  }

  & > thead th {
    color: var(--bs-secondary);
  }

  & > tbody > tr:nth-child(even) > td {
    background-color: var(--bs-light);
  }
}

.overlap {
  box-shadow: inset -4px 0px 3px -3px rgba(0, 0, 0, 0.1) !important;
}

.bi-sticky-col {
  background-color: var(--bs-white);
  left: -24px;
  position: sticky;
  z-index: 1;
}
