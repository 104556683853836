// Utility
.bg-light-green {
  background: $sn-clr-light-green;
}

.bg-dark-grey {
  background: $sn-clr-dark-grey;
}

.bg-sn-black {
  background: $sn-clr-black;
}

.bg-sn-sand {
  background: $sn-clr-sand;
}

.bg-sn-fire {
  background: $sn-clr-fire;
}

.border-ui-grey {
  border-color: $sn-clr-ui-grey;
}

.border-dark-grey {
  border-color: $sn-clr-dark-grey;
}

.font-weight-bold {
  font-weight: $font-weight-bold;
}

.font-weight-semibold {
  font-weight: $font-weight-semibold;
}

.border-radius-100 {
  border-radius: 100px;
}

.cursor-grab {
  cursor: grab;
}

.text-small {
  font-size: $font-size-sm;
}

.fill-green {
  fill: $sn-clr-green;
}

.outline-visible {
  &:focus,
  &:focus-visible {
    --bs-btn-focus-shadow-rgb: var(--bs-primary-rgb);
    box-shadow: 0 0 0 .25rem rgba(var(--bs-btn-focus-shadow-rgb), .25);
    outline: none;
  }
}

.flush {
  margin-right: calc(var(--bs-gutter-x) * -0.5);
  margin-left: calc(var(--bs-gutter-x) * -0.5);
}

.text-accent-purple {
  color: $sn-clr-purple;
}
.text-accent-grey {
  color: $sn-clr-breadcrumb-grey;
}
.text-accent-deep-grey {
  color: $sn-clr-deep-grey;
}

.text-accent-dark-blue {
  color: var(--bs-sn-clr-dark-blue);
}

.text-accent-land {
  color: $sn-clr-land;
}

.text-accent-storm {
  color: $sn-clr-storm;
}

.text-accent-yellow {
  color: $sn-clr-yellow;
}

.text-xsmall {
  font-size: 0.75rem;
}
.font-noto {
  font-family: 'Noto Sans', sans-serif;
}
.fw-accent {
  font-weight: 700;
}