// .container-small {
//   margin: auto;
// }
// @media (min-width: 1200px) {
//   .container-small { width: 936px; }
// }
// @media (min-width: 992px) and (max-width: 1200px) {
//   .container-small { width: 776px; }
// }

// @media (min-width: 768px) and (max-width: 992px) {
//   .container-small { width: 600px; }
// }

.page-title {
  margin:1em 0 0.5em 0;
  padding-bottom:0.25em;
  border-bottom:1px solid #eee;
}

// TinyMCE styles
.border-0 .tox-tinymce {
  border: none !important;
}

.tox-tinymce {
  border-radius: 7px !important;
}

.tox-editor-header{
  box-shadow: none !important;
  border-bottom: $border-grey !important;
}

// .page-description {
//   margin:0 0 1.5em 0;
// }

// .flash-container { padding: 5px; }

// .row-space-top {
//   margin-top: 16px;
// }

// .row-space-bottom {
//   margin-bottom: 16px;
// }

// Fix the letter spacing on bootstrap input placeholders
// Without this the letters are too close together.
// Also, override the user agent stylesheet for button
.form-control::placeholder,
button.btn {
  letter-spacing: 0.8px;
}
