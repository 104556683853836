// Outline accordion
.outline .accordion-button:not(.collapsed) {
  color: inherit;
  background-color: inherit;
  box-shadow: none;
}

.outline .accordion-button {
  padding: $spacer*.75 $spacer*.5;
}

.outline .accordion-title {
  background-color: transparent;
  border: none;
  border-radius: 0;
  color: var(--bs-black);
  display: block;
  font-size: 1rem;
  font-weight: 600;
  padding: $spacer*.5;
  text-align: left;
  width: 100%;
  
  &:focus,
  &:focus-visible {
    box-shadow: var(--bs-accordion-btn-focus-box-shadow);
    outline: none;
  }
}

.outline .accordion-button:hover {
  background-color: $sn-clr-light-green;
}

.outline .accordion-header {
  line-height: 1.4375;
  padding: $spacer $spacer*.25;

  &:focus-within,
  &:hover,
  &-active {
    background-color: $sn-clr-light-green;
    color: $sn-clr-green;
  }
}

.outline .list-group-item {
  padding: $spacer*.75 $spacer*.25 $spacer*.75 $spacer*.75;
  
  & > button:focus,
  & > button:focus-visible {
    box-shadow: var(--bs-accordion-btn-focus-box-shadow);
    outline: none;
  }
}

.outline .list-group-item:hover,
.outline .list-group-item-active {
  background-color: $sn-clr-light-green;
}

.outline .accordion-header,
.outline .list-group-item {
  .cursor-grab {
    visibility: hidden;
  }

  &:hover,
  &:focus,
  &:focus-within {
    .cursor-grab {
      visibility: visible;
    }
  }
}

.accordion-position {
  color: $sn-clr-dark-grey;
}
