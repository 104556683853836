.dashboard-page {
  .panel {
    background-color: #fdfdfd;
  }
  .panel-title {
    display: inline-block;
    width: calc(100% - 35px);
  }
  .panel-heading .badge {
    background-color: #337ab7;
  }
  table.pod-stats {
    table-layout: fixed;
    border-width: 0;

    & th, & td {
      border-left-width: 1px;

      &:first-child {
        border-left-width: 0;
      }
    }
  }
  table th,td {
    text-align: center;
  }
  table th.transparent,td.transparent {
    opacity: 0.2;
  }
  table.pod-stats .question{
    display: inline-block;
    position: absolute;
    color: white;
    background-color: grey;
    font-size: 10px;
    height: 14px;
    width: 14px;
    border-radius: 8px;
  }
  td .badge{
    margin-right: 5px;
  }
  .priority1 {
    background-color: #49af1d;
  }
  .priority2 {
    background-color: #f4d942;
  }
  .priority3 {
    background-color: #dc1818;
  }
  .darken {
    .priority1 {
      background-color: darken(#49af1d, 20);
    }
    .priority2 {
      background-color: darken(#f4d942, 20);
    }
    .priority3 {
      background-color: darken(#dc1818, 20);
    }
  }
  .panel-footer {
    text-align: center;
  }
  .priority1-text {
    color: #49af1d;
  }
  .priority2-text {
    color: #f4d942;
  }
  .priority3-text {
    color: #dc1818;
  }
  .label a {
    color: white;
  }

  
}
.journal-flag {
  color: var(--bs-sn-clr-deep-grey);
}
