.form-builder {
  counter-reset: question;
}

.question {
  counter-reset: option;
  margin-inline: -0.5rem;
  margin-top: 0.5rem;
  padding: 0.5rem;

  & + & {
    border-top: 1px solid var(--bs-border-color);
    padding-top: 1rem;
  }

  .cursor-grab {
    visibility: hidden;
  }

  &.is-dragging,
  &:hover {
    background-color: var(--bs-sn-clr-light-green);
    border-color: transparent;
    border-radius: var(--bs-border-radius);

    .cursor-grab {
      visibility: visible;
    }
  }
  
  &.is-dragging {
    .drag-hide {
      display: none;
    }
  }

  .question-number > label::before {
    counter-increment: question;
    content: counter(question) ". ";
  }
}

.options-table {
  width: 100%;

  & tr > td:first-child::before {
    content: counter(option, lower-alpha) ")";
    counter-increment: option;
    padding-right: 0.5rem;
  }
}