.pagination-sm ul.pagination {
  --bs-pagination-padding-x: 0.5rem;
  --bs-pagination-padding-y: 0.25rem;
  --bs-pagination-font-size: 0.75rem;

  margin-bottom: 0;
}

.pagination-info > b {
  font-weight: normal;
}
