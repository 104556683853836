.participant-info-popover {
  width: 24rem;
}

.participant-info {
  .user-detail {
    color: var(--bs-sn-clr-dark-grey);
  }
  
  ul.list-unstyled {
    & > li {
      align-items: baseline;
      display: flex;
      font-weight: 500;
      padding: 0.25rem 0;
    }

    li + li {
      border-top: 1px solid var(--bs-border-color);
    }

    .property-title {
      color: var(--bs-sn-clr-deep-grey);
      margin-right: 0.5rem;
    }
    .property-detail {
      color: var(--bs-sn-clr-dark-grey);
      margin-left: auto;
      text-align: right;
    }
    .property-detail-secondary {
      color: var(--bs-sn-clr-deep-grey);
      font-weight: normal;
      margin-left: 0.25rem;
    }
  }

  button.copy-button {
    color: var(--bs-sn-clr-deep-grey);
    padding: 0;
    text-decoration: none;

    &:hover,
    &:focus {
      color: var(--bs-secondary);
    }
  }
}
